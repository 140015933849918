import React from 'react'
import { useSelector } from 'react-redux';
import { green } from '@material-ui/core/colors';
import * as Liff from '../../apis/liff';
import Box from '@material-ui/core/Box';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from '@material-ui/core/Typography';
import Layout from '../../components/v2/Layout';
import Button from '../../components/v2/Button';
import { navigate } from 'gatsby';

export default () => {

  const session = useSelector(state => state.session);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     onClose();
  //   }, 4000);
  // }, []);

  const onClose = () => {
    navigate('/membership');
    // if (session.source !== 'browser') {
    //   if (Liff.isInClient()) {
    //     Liff.closeWindow();
    //   } else {
    //     window.location.href = 'me.moronline://exit';
    //   }
    // }
  }

  return (
    <Layout bg="bg4" title="">
      <Box p={2} display="flex" alignItems="center" flexDirection="column">
        <CheckCircleOutlineIcon style={{ color: green[500], fontSize: 50 }} />
        <Typography variant="h5" component="h1" color="textPrimary" style={{ margin: '15px 0px 5px', fontSize: 20 }}>
          รับของขวัญสำเร็จ!
        </Typography>
        <Typography variant="h5" component="h1" color="textPrimary" style={{ fontSize: 25 }}>
          Membership card
        </Typography>
        {/* {
          session.source !== 'browser' && ( */}
            <Box pt={4}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                onClick={() => onClose()}
              >
                กลับหน้าสมาชิก
              </Button>
            </Box>
          {/* ) */}
        {/* } */}
      </Box>
    </Layout >
  );
}
